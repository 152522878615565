import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* fontawesome */
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

/* graphql */
import { provideApolloClient } from "@vue/apollo-composable";
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'

/* pwa */
import "./registerServiceWorker";

library.add(faChevronRight);
dom.watch();

const app = createApp(App)
    .use(IonicVue)
    .use(router);

const apolloClient = new ApolloClient({
    link: createHttpLink({
        uri: process.env.VUE_APP_DIRECTUS + "graphql"
    }),
    cache: new InMemoryCache()
})
provideApolloClient(apolloClient);

router.isReady().then(() => {
    app.mount('#app');
});