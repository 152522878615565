import VideoDisplay from '@/views/VideoDisplay.vue';
import VideoSelectionView from '@/views/VideoSelectionView.vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import VideoTest from "@/views/VideoTest.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/:language",
        name: "video-selection",
        component: VideoSelectionView
    },
    {
        path: "/:language/:videoId",
        name: "video-display",
        component: VideoDisplay
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
