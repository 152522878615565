export type I18N = {
    back: string;
}

export const german: I18N = {
    back: "zurück"
}

export const english: I18N = {
    back: "back"
}

export const french: I18N = {
    back: "retour"
}

export function getI18N(language: string, key: keyof I18N): string {
    const localeLiteral: Record<string, I18N> = {
        "de": german,
        "en": english,
        "fr": french
    };
    return localeLiteral[language][key] ?? "<missing translation>";
}